@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.yoshe {
    box-sizing: border-box;
    position: relative;
    display: block;

}
