@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.product-intro {
    box-sizing: border-box;
    position: relative;
    display: block;
    background-color: $toyota-grey;

    .holders {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0px 720px 0px 0px;
        height: 400px;

        .img-holder {
            box-sizing: border-box;
            position: absolute;
            display: block;
            right: 0px;
            top: 0px;
            width: 720px;
            height: 100%;
            overflow: hidden;

            img {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .txt-holder {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 0px 20px 0px 0px;
            width: 100%;
            max-width: 580px;
            top: 50%;
            transform: translateY(-50%);

            .heading {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px;
                font-family: $toyota-type-regular;
                font-size: 40px;
                font-weight: normal;
            }

            .subheading {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 10px 0px 0px 0px;
                font-family: $toyota-type-regular;
                font-size: 30px;
                font-weight: normal;
            }

            .paragraphs {
                margin: 20px 0px 0px 0px;
            }

            @media only screen and (max-width: 600px) {
                .heading {
                    font-size: 30px;
                }

                .subheading {
                    font-size: 22px;
                }

                .paragraphs {
                    margin: 20px 0px 0px 0px;
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .holders {
            padding: 0px;
            height: auto;

            .img-holder {
                position: relative;
                left: -20px;
                right: auto;
                width: calc(100% + 40px);

                img {
                    height: 400px;
                }
            }

            .txt-holder {
                padding: 30px 0px 40px 0px;
                top: 0px;
                max-width: none;
                transform: none;
            }
        }
    }
}

