@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.layout {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 0px auto;
    padding: 210px 0px 0px 0px;
    width: 100%;
    background-color: #282830;

    .page-content {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0px 0px 80px 0px;
        animation: fadeIn 0.5s ease-in;
        background-color: #FFFFFF;
    }

    .inner-content {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        padding: 0px 20px;
        width: 100%;
        max-width: 1440px;
    }
}
