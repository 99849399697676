@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.paragraphs {
    box-sizing: border-box;
    position: relative;
    display: block;

    p {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 10px 0px 0px 0px;
        font-size: 20px;

        a {
            text-decoration: none;
            font-family: $toyota-type-semibold;
            color: $toyota-red;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    p:first-child {
        margin: 0px;
    }

    @media only screen and (max-width: 600px) {
        p {
            font-size: 14px;
        }
    }
}
