@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.footer {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding: 20px 0px;
    width: 100%;
    background-color: $toyota-grey;

    .copyright {
        box-sizing: border-box;
        position: relative;
        display: block;
        font-size: 18px;
    }
}
