@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.not-found {
    box-sizing: border-box;
    position: relative;
    display: block;

    .intro-section {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 100px 0px;
        background: $toyota-grey;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px;
            font-family: $toyota-type-regular;
            font-size: 35px;
            font-weight: normal;
        }

        .paragraphs {
            margin: 70px 0px 0px 0px;
        }
    }
}
