@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.product-resources {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 80px 0px 0px 0px;

    .cols {
        box-sizing: border-box;
        position: relative;
        display: block;

        .img-holder {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0px 10px 0px 0px;
            width: 50%;

            img {
                box-sizing: border-box;
                position: relative;
                display: block;
                width: 100%;
                max-width: 680px;
            }
        }

        .txt-holder {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0px 0px 0px 10px;
            width: 50%;
            text-align: right;

            .txt-wrapper {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin: 0px;
                width: 100%;
                max-width: 640px;
                text-align: left;

                .instruction {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px;
                    font-family: $toyota-type-regular;
                    font-size: 30px;
                }

                .video-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 30px 0px 0px 0px;
                }

                .guide-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 20px 0px 0px 0px;
                }

                .manual-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 20px 0px 0px 0px;
                }

                .rest-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 20px 0px 0px 0px;
                }

                @media only screen and (max-width: 600px) {
                    .instruction {
                        font-size: 22px;
                    }      
                }
            }
        }        
    }
}

@media only screen and (max-width: 800px) {
    .product-resources {
        margin: 40px 0px 0px 0px;

        .cols {
            .img-holder {
                padding: 0px;
                width: 100%;

                img {
                    max-width: none;
                }
            }

            .txt-holder {
                margin: 40px 0px 0px 0px;
                padding: 0px;
                width: 100%;

                .txt-wrapper {
                    background-color: $toyota-grey;
                    padding: 20px;
                    max-width: none;
                }
            }
        }
    }
}