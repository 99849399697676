@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.language-selector {
    box-sizing: border-box;
    position: absolute;
    display: block;
    top: 12px;
    right: 14px;

    .flag {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0px 1px;
        width: 42px;
        cursor: pointer;

        img {
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 100%;
        }

        .circle {
            box-sizing: border-box;
            position: absolute;
            display: block;
            border: 2px solid $toyota-red;
            border-radius: 50%;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    .flag:hover {
        .circle {
            opacity: 1;
        }
    }

    .flag.active {
        .circle {
            opacity: 1;
        }
    }
}
