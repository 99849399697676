@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.home {
    box-sizing: border-box;
    position: relative;
    display: block;

    .intro-section {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: clamp( 10px, 2vw, 50px);
        padding-bottom: clamp( 10px, 3.7vw, 30px);
        text-align: center;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px;
            font-family: $toyota-type-regular;
            font-size: 30px;
            font-weight: normal;
        }

        .paragraphs {
            margin: clamp( 20px, 3.7vw, 40px);
        }
    }

    @media only screen and (max-width: 600px) {
        .intro-section {

            .heading {
                font-size: 22px;
            }
        }
    }

    .products-section {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 50px 0px;
        background: $toyota-grey;
        overflow: hidden;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px;
            font-family: $toyota-type-regular;
            font-size: 30px;
            font-weight: normal;
            text-align: center;
        }

        .boxes {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px -20px 0px 0px;
            text-align: center;

            .box {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding: 20px 20px 0px 0px;
                width: 20%;

                .border {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    border: 2px solid #D9D9D9;
                    background: #FFFFFF;

                    .img-holder {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        border-bottom: 2px solid #D9D9D9;
                        width: 100%;

                        img {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            width: 100%;
                        }
                    }

                    .txt-holder {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 0px auto;
                        padding: 16px 20px 20px 20px;
                        width: 100%;
                        max-width: 312px;
                        text-align: center;

                        .name {
                            box-sizing: border-box;
                            position: relative;
                            display: block;
                            font-family: $toyota-type-regular;
                            font-size: 25px;
                        }

                        .general-button {
                            margin: 20px 0px 0px 0px;
                        }
                    }
                }
            }

            @media only screen and (max-width: 1100px) {
                .box {
                    width: 33.33%;
                }
            }

            @media only screen and (max-width: 800px) {
                .box {
                    width: 50%;
                }
            }

            @media only screen and (max-width: 600px) {
                .box {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .products-section {
            padding: 40px 0px;

            .heading {
                font-size: 22px;
            }

            .boxes {
                margin: -20px -20px 0px 0px;

                .box {
                    padding-top: 60px;
                }
            }
        }
    }
}
