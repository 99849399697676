@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.banner {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 0px auto;
    padding-top: 66px;
    width: 1400px;
    max-width: 100%;

    @media only screen and (max-width: 1430px) {
        padding-top: 20px;
    }

    .banner-image {
        width: 100%;
        height: auto;
    }

    .title-container {
        width: 100%;
        position: absolute;
        top: 138px;
        left: 0;

    @media only screen and (max-width: 1427px) {
        top: clamp( 35px, 6vw, 103px);
    }

        .title {
            box-sizing: border-box;
            display: block;
            margin: 0px;
            width: clamp( 20px, 35vw, 470px);
            font-size: clamp(8px, 3.1vw, 40px);
            font-weight: normal;
            color: #ffffff;
            position: absolute;
            right: clamp( 0px, 4vw, 4vw);
        }
    }
}