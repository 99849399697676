@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.language-popup {
    box-sizing: border-box;
    position: fixed;
    display: block;
    padding: 20px;
    left: auto;
    top: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.7);

    .content-box {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        padding: 20px 20px 40px 20px;
        border-radius: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        max-width: 1440px;
        max-height: calc(100vh - 40px);
        background-color: #282830;
        overflow: auto;

        .content-img {
            box-sizing: border-box;
            position: absolute;
            display: block;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            opacity: 0.05;
        }

        .content-wrapper {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0px auto;
            width: 100%;
            max-width: 460px;
            text-align: center;

            .title {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px;
                font-family: $toyota-type-regular;
                font-size: 60px;
                font-weight: normal;
                color: #FFFFFF;
            }

            .instruction {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 10px 0px 0px 0px;
                font-family: $toyota-type-regular;
                font-size: 35px;
                color: #FFFFFF;
            }

            .languages {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 40px 0px 0px 0px;
                border-bottom: 3px solid #5D5D6F;
                text-align: left;

                .language {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 22px 0px 30px 76px;
                    border-top: 3px solid #5D5D6F;
                    text-decoration: none;
                    cursor: pointer;

                    .flag {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        left: 0px;
                        top: 17px;
                        width: 68px;
                        height: 68px;
                        opacity: 0.3;
                    }

                    .name {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-family: $toyota-type-regular;
                        font-size: 35px;
                        color: #5D5D6F;
                    }

                    .arrow {
                        box-sizing: border-box;
                        position: absolute;
                        display: block;
                        right: 4px;
                        top: 21px;
                        width: 60px;
                        height: 60px;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.25 12C2.25 13.9284 2.82183 15.8134 3.89317 17.4168C4.96452 19.0202 6.48726 20.2699 8.26884 21.0078C10.0504 21.7458 12.0108 21.9389 13.9021 21.5627C15.7934 21.1864 17.5307 20.2578 18.8943 18.8943C20.2579 17.5307 21.1865 15.7934 21.5627 13.9021C21.9389 12.0108 21.7458 10.0504 21.0078 8.26883C20.2699 6.48725 19.0202 4.96451 17.4168 3.89317C15.8134 2.82182 13.9284 2.25 12 2.25C9.41498 2.25273 6.93661 3.28083 5.10872 5.10872C3.28083 6.93661 2.25273 9.41497 2.25 12ZM20.25 12C20.25 13.6317 19.7661 15.2267 18.8596 16.5834C17.9531 17.9402 16.6646 18.9976 15.1571 19.622C13.6496 20.2464 11.9908 20.4098 10.3905 20.0915C8.79016 19.7731 7.32015 18.9874 6.16637 17.8336C5.01259 16.6798 4.22685 15.2098 3.90852 13.6095C3.59019 12.0091 3.75357 10.3503 4.37799 8.84286C5.00242 7.33537 6.05984 6.04689 7.41655 5.14037C8.77325 4.23385 10.3683 3.75 12 3.75C14.1873 3.75248 16.2843 4.62247 17.8309 6.1691C19.3775 7.71574 20.2475 9.81272 20.25 12ZM9.96937 7.71937C10.039 7.64964 10.1217 7.59432 10.2128 7.55658C10.3038 7.51883 10.4014 7.49941 10.5 7.49941C10.5986 7.49941 10.6962 7.51883 10.7872 7.55658C10.8783 7.59432 10.961 7.64964 11.0306 7.71937L14.7806 11.4694C14.8504 11.539 14.9057 11.6217 14.9434 11.7128C14.9812 11.8038 15.0006 11.9014 15.0006 12C15.0006 12.0986 14.9812 12.1962 14.9434 12.2872C14.9057 12.3782 14.8504 12.461 14.7806 12.5306L11.0306 16.2806C10.8899 16.4214 10.699 16.5004 10.5 16.5004C10.301 16.5004 10.1101 16.4214 9.96937 16.2806C9.82864 16.1399 9.74958 15.949 9.74958 15.75C9.74958 15.551 9.82864 15.3601 9.96937 15.2194L13.1897 12L9.96937 8.78062C9.89964 8.71097 9.84432 8.62825 9.80658 8.5372C9.76884 8.44615 9.74941 8.34856 9.74941 8.25C9.74941 8.15143 9.76884 8.05384 9.80658 7.96279C9.84432 7.87174 9.89964 7.78902 9.96937 7.71937Z' fill='%235D5D6F'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        background-position: center;
                    }
                }

                .language:hover {
                    .flag {
                        opacity: 1;
                    }

                    .name {
                        color: #FFFFFF;
                    }

                    .arrow {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.25 12C2.25 13.9284 2.82183 15.8134 3.89317 17.4168C4.96452 19.0202 6.48726 20.2699 8.26884 21.0078C10.0504 21.7458 12.0108 21.9389 13.9021 21.5627C15.7934 21.1864 17.5307 20.2578 18.8943 18.8943C20.2579 17.5307 21.1865 15.7934 21.5627 13.9021C21.9389 12.0108 21.7458 10.0504 21.0078 8.26883C20.2699 6.48725 19.0202 4.96451 17.4168 3.89317C15.8134 2.82182 13.9284 2.25 12 2.25C9.41498 2.25273 6.93661 3.28083 5.10872 5.10872C3.28083 6.93661 2.25273 9.41497 2.25 12ZM20.25 12C20.25 13.6317 19.7661 15.2267 18.8596 16.5834C17.9531 17.9402 16.6646 18.9976 15.1571 19.622C13.6496 20.2464 11.9908 20.4098 10.3905 20.0915C8.79016 19.7731 7.32015 18.9874 6.16637 17.8336C5.01259 16.6798 4.22685 15.2098 3.90852 13.6095C3.59019 12.0091 3.75357 10.3503 4.37799 8.84286C5.00242 7.33537 6.05984 6.04689 7.41655 5.14037C8.77325 4.23385 10.3683 3.75 12 3.75C14.1873 3.75248 16.2843 4.62247 17.8309 6.1691C19.3775 7.71574 20.2475 9.81272 20.25 12ZM9.96937 7.71937C10.039 7.64964 10.1217 7.59432 10.2128 7.55658C10.3038 7.51883 10.4014 7.49941 10.5 7.49941C10.5986 7.49941 10.6962 7.51883 10.7872 7.55658C10.8783 7.59432 10.961 7.64964 11.0306 7.71937L14.7806 11.4694C14.8504 11.539 14.9057 11.6217 14.9434 11.7128C14.9812 11.8038 15.0006 11.9014 15.0006 12C15.0006 12.0986 14.9812 12.1962 14.9434 12.2872C14.9057 12.3782 14.8504 12.461 14.7806 12.5306L11.0306 16.2806C10.8899 16.4214 10.699 16.5004 10.5 16.5004C10.301 16.5004 10.1101 16.4214 9.96937 16.2806C9.82864 16.1399 9.74958 15.949 9.74958 15.75C9.74958 15.551 9.82864 15.3601 9.96937 15.2194L13.1897 12L9.96937 8.78062C9.89964 8.71097 9.84432 8.62825 9.80658 8.5372C9.76884 8.44615 9.74941 8.34856 9.74941 8.25C9.74941 8.15143 9.76884 8.05384 9.80658 7.96279C9.84432 7.87174 9.89964 7.78902 9.96937 7.71937Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                    }
                }
            }

            .copyright {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 40px 0px 0px 0px;
                font-size: 18px;
                color: #FFFFFF;
            }
        }

        @media only screen and (max-width: 530px) {
            .content-wrapper {
                .title {
                    font-size: 40px;
                }

                .instruction {
                    font-size: 25px;
                }

                .languages {
                    .language {
                        padding: 17px 0px 25px 60px;

                        .flag {
                            top: 13px;
                            width: 50px;
                            height: 50px;
                        }

                        .name {
                            font-size: 25px;
                        }

                        .arrow {
                            top: 16px;
                            width: 44px;
                            height: 44px;
                        }
                    }

                }
            }
        }
    }
}
