$toyota-type-regular: 'ToyotaType-Regular', sans-serif;
$toyota-type-book: 'ToyotaType-Book', sans-serif;
$toyota-type-semibold: 'ToyotaType-Semibold', sans-serif;


@font-face {
    font-family: 'ToyotaType-Regular';
    src: url('./fonts/ToyotaType-Regular.eot');
    src: url('./fonts/ToyotaType-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ToyotaType-Regular.woff2') format('woff2'),
        url('./fonts/ToyotaType-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ToyotaType-Book';
    src: url('./fonts/ToyotaType-Book.eot');
    src: url('./fonts/ToyotaType-Book.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ToyotaType-Book.woff2') format('woff2'),
        url('./fonts/ToyotaType-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ToyotaType-Semibold';
    src: url('./fonts/ToyotaType-Semibold.eot');
    src: url('./fonts/ToyotaType-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ToyotaType-Semibold.woff2') format('woff2'),
        url('./fonts/ToyotaType-Semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
