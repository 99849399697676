@import "../../styles/fonts";
@import "../../styles/colours";
@import "../../styles/animations";

.general-button {
    box-sizing: border-box;
    position: relative;
    display: block;
    border: 2px solid $toyota-red;
    padding: 16px 18px 20px 19px;
    outline: none;
    width: 100%;
    text-decoration: none;
    text-align: center;
    background: $toyota-red;
    appearance: none;
    cursor: pointer;

    .label {
        box-sizing: border-box;
        position: relative;
        display: block;
        font-family: $toyota-type-regular;
        font-size: 20px;
        color: #FFFFFF;
    }
}

.general-button:hover {
    border: 2px solid #282830;
    background: #282830;
}

.general-button.style1 {
    border: 2px solid #D9D9D9;
    background: #D9D9D9;

    .label {
        color: #282830;
    }
}

.general-button.style1:hover {
    border: 2px solid #282830;
    background: #FFFFFF;
}

.general-button.style2 {
    border: 2px solid #282830;
    background: #282830;
}

.general-button.style2:hover {
    border: 2px solid #282830;
    background: #FFFFFF;

    .label {
        color: #282830;
    }
}